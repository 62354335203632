<template>
  <base-section id="pro-features">
    <base-section-heading title="Send Enquiry"></base-section-heading>

    <v-container>
      <v-theme-provider dark>
        <v-form width
        >
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="name" flat label="Name*" :rules="nameRules" required solo></v-text-field>
              <v-text-field
                v-model="phonenumber"
                flat
                type="number"
                label="Phone Number*"
                :rules="phonenumberRules"
                required
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="email" flat label="Email*" :rules="emailRules" required solo></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="subject"
                flat
                label="Subject*"
                :rules="subjectRules"
                required
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="message"
                flat
                label="Message*"
                :rules="messageRules"
                required
                solo
              ></v-textarea>
            </v-col>

            <v-col class="mx-auto" cols="auto">
              <v-btn color="accent" @click="sendEmail" x-large>Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-theme-provider>
    </v-container>
  </base-section>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  name: "SectionProChart",

  data() {
    return {
      valid: false,
      name: null,
      phonenumber: null,
      subject: null,
      email: null,
      message: null,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      phonenumberRules: [v => !!v || "Phonenumber is required"],
      subjectRules: [v => !!v || "Subject is required"],
      messageRules: [v => !!v || "Message is required"],
      nameRules: [v => !!v || "Name is required"]
    };
  },

  methods: { 
sendEmail() {
  this.validate();
      if (this.valid === true) {
        this.valid = false;
      try {
       emailjs.send("service_fa57urn","template_ycldt8j",{
        from_name: this.name,
        to_name: "fleetop",
        message: this.message +":: Mobile::" + this.phonenumber +":: e-mail::" + this.email + ":: Subject :: " + this.subject
        });

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      alert("Thank You!! E-Mail Received..You will hear from us soon");
      window.location.reload();
    }
},
    validate() {
      console.log(
        " this.name this.email this.subject this.message",
        this.name,
        this.phonenumber,
        this.email,
        this.subject,
        this.message
      );
      if (
        this.name !== null &&
        this.email !== null &&
        this.subject !== null &&
        this.message !== null &&
        this.phonenumber !== null
      ) {
        this.valid = true;
        return this.valid;
      } else {
        this.valid = false;
        alert("Please input all mandatory fields marked as *");
        return this.valid;
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  }
};
</script>
